/* Ham.css */
.hamburger-menu {
  position: relative;
}

.hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 1000; /* Ensure it stays on top */
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.hamburger .line.open {
  background: linear-gradient(45deg, #b87333, #a65e2e, #8c4b2e, #754321) !important;
}

.hmb:hover {
  background: linear-gradient(45deg, #2e2e2e, #1c1c1c, #0d0d0d, #000000) !important;
  color: #FFD700;
}
.nav-menu {
  display: none;
  position: absolute;
  top: 40px;
  right: 0;
  border-radius: 5px;
  width: 4em;
  text-align: center;
  background: linear-gradient(45deg, #ffd700, #daa520, #ffcc00, #b8860b) !important; 
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ham {
    width: 3%;
    text-align: center;
    padding: 0.4em 0.4em;
}

.nav-menu ul li {
  margin: 10px 0;
}

.nav-menu ul li a {
  text-decoration: none;
  font-size: 18px;
}

/* When the menu is open, display it */
.nav-menu.open {
  display: block;
}
