.admain {
    padding: 3em 0 0 0;
}

.pad {
    padding: 1em;
    transition: transform 0.3s ease, filter 0.3s ease;
    filter: brightness(0.8);
}
.pad:hover {
    transform: scale(1.01) translateX(1px);
    filter: brightness(1.1);
}


.product {
    color: white;
    margin: auto;
    text-align: center;
}

.productmain {
    padding: 0em 0em 0em 0em;
}

.paybutton {
    padding: 1em 1.5em;
}

.description {
    width: 50%;
    margin: auto;
}

button {
  background: #FFD700;
  border: none;
  border-radius: 4px;
  padding: 0 0.4em;
}

.pagecount {
 padding: 0.5em;
}

.readarticle {
  color: white;
}

.readarticle:hover {
  color: black;
  width: 300px;
  background: linear-gradient(45deg, #ffd700, #daa520, #ffcc00, #b8860b) !important; 
}

ul {
}

.li {
}

.li:hover {
  background: linear-gradient(45deg, #fff9e6, #fef3cc, #ffebb3, #ffe599);
  -webkit-background-clip: text; /* Clips the background to the text */
  color: transparent; /* Text remains transparent to show the gradient */
}

.ad {
    color: white;
}

::selection {
  background: #FFD700;
  color: black;
}

.symbol {
    margin: auto;
    transition: transform 0.3s ease, filter 0.3s ease;
    filter: brightness(0.8);
    border-radius: 10px;
}

.symbol:hover {
  transform: scale(1.01) translateX(1px);
  filter: brightness(1.1);
}

.symbols {
    max-width: 100%;
    flex-wrap: wrap;
    display: flex;
    gap: 10px;
}

@media screen and (max-width: 959px) {
	/* 959px以下に適用されるCSS（タブレット用） */
}

@media screen and (max-width: 480px) {
  li {
      padding: 0.3em 0em;
  }
  .description {
      width: 90%;
      margin: auto;
  }
}
