@media screen and (max-width: 480px) {
    .manga {
      width: 10px important;
      max-width: 100% !important;
      text-align: center;
      margin: auto;
    }
    .cont2 {
      width: 100%;
      color: white;
    }
    .blog-section-container {
      padding: 2.5em 10px;
      color: white;
      margin: auto;
      max-width: 100%;
    }
} 

.manga {
  width: 10px important;
  max-width: 500px !important;
  text-align: center;
  margin: auto;
}

.manga img {
}

@media screen and (min-width: 480px) {
  .cont2 {
    color: white;
    width: 50%;
    margin: auto;
  }
  .blog-section-container img {
    max-width: 100%;
    height: auto; /* Maintain the aspect ratio */
    display: block; /* Prevent inline elements from causing layout issues */
    margin: 0 auto; /* Center the image */
  }
  
  .blog-section-container {
    padding: 2.5em 10px;
    color: white;
    margin: auto;
    max-width: 60%;
  }
}

.blog-section img {
  max-width: 100%; /* Ensure the image does not exceed the width of the container */
  height: auto; /* Maintain the aspect ratio */
  display: block; /* Prevent inline elements from causing layout issues */
  margin: 0 auto; /* Center the image */
}

.blog-section {
  padding: 2.5em 10px;
  color: white;
}

.blog-list {
  margin: auto;
  width: 100%;
}

.blog-list-title {
}

.blog-content h1 {
  color: red;
  font-size: 2em;
  margin-bottom: 0.5em;
}

.blog-content p {
  color: red;
  line-height: 1.6;
}

.blog-content ul {
  list-style-type: disc;
  margin-left: 20px;
}
